import React, {BaseSyntheticEvent} from "react";


type LoginFormProps = {
  // onUserCreate: ()=>void //needed?
  //onLoading: ()=>void // needed?
  statusHandler: (loading: boolean, message: string)=> void //needed?
  authDataHandler: (authData:
                        {username: string
                          token: string
                          loggedIn: boolean
                        })=> void //needed?
}

type LoginFormState = {
  username: string
  password: string
}

let INITIAL_STATE = {
  username: '',
  password: ''
}

type AuthResponse = {
  message: string
  token: string
}

export class LoginForm extends React.Component<LoginFormProps, LoginFormState> {

  constructor(props: LoginFormProps) {
    super(props);
    this.state = INITIAL_STATE
    console.log(this.state)
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleInputChange(event: BaseSyntheticEvent) {
    const target = event.target;
    if (target == null) return
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name

    this.setState<never>({ //disable type check (PITA), otherwise field by field, or using Formik or React hook form
      [name]: value
    })
  }

  login() {
    console.log("should login " + this.state.username);
    this.props.statusHandler(true, "logging in")
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(this.state)
    };
    fetch('auth/login/', requestOptions)
    ///.then(response => response.json())
    .then(response => {
      console.log(response);
      if (!response.ok) {
        this.props.statusHandler(false, "failed to login")
        this.setState(INITIAL_STATE)
        throw new Error(response.statusText)
      }
      return response.json();
    })
    .then((response: AuthResponse)=>{
      console.log(response)
      this.props.authDataHandler({
        username: '',
        token: response.token,
        loggedIn: true
      })
      this.props.statusHandler(false, "welcome")
    })
    ;
    // //.then(data => this.setState({ postId: data.id }));
    //
    // fetch('user/' + user.username, { method: 'POST' })
    // .then(() => {
    //   this.setState({ message: user.username + ' created' })
    //   //this.loadUsers()
    // });
  }

  render() {
    return <div>
      <table>
        <tr>
          <td><label>Username:</label></td>
          <td><input name="username" type="text" value={this.state.username} onChange={this.handleInputChange}/></td>
        </tr>
        <tr>
          <td><label>Password</label></td>
          <td><input name="password" type="password" autoComplete="new-password" value={this.state.password} onChange={this.handleInputChange}/></td>
        </tr>
        <tr>
          <td><label></label></td>
          <td><button type="button" value="login" onClick={(e) => this.login()}>Login</button></td>
        </tr>
      </table>

    </div>;
  }
}
