import React from 'react';
import './UserList.css';
import Client from './Client';
import bin from "./img/bin.png";
import {UserForm} from "./UserForm";

type UserListProps = {
  title: string,
  statusHandler: (loading: boolean, message: string)=> void
}

type UserListState = {
  // define a class here?
  users: { username: string; created: string; email: string }[]
  message: string
}

export class UserList extends React.Component <UserListProps, UserListState> {

  //https://fettblog.eu/typescript-react/components/

  constructor(props: UserListProps) {
    super(props);
    this.state = {
      users: [],
      message: ''
    }

  }

  updateStatus(loading: boolean, message: string){
    this.props.statusHandler(loading, message)
  }
  loading(){
    this.props.statusHandler(true, 'loading')
  }

  loaded(){
    this.props.statusHandler(false, 'loaded')
  }

  loadUsers() {
    this.loading()
    Client.api<{ users: { username: string; created: string; email: string }[] }>('user')
    .then(({users}) => {
      this.loaded()
      console.log(users)
      this.setState({
        users: users.map((u,index)=>{
          u.created = this.simplifyDate(u.created)
          return u
        }),
      })
      //return users
    })
    .catch(error => {
      /* show error message */
    })
  }

  componentDidMount() {
    this.loadUsers()
  }

  deleteUser(username: string) {
    console.log("should delete " + username);
    this.updateStatus(true, 'deleting '+ username)
    fetch('user/'+username, { method: 'DELETE' })
      .then(() => {
        this.updateStatus(false, 'deleted')
        this.setState({ message: username + ' deleted successful' })
        this.loadUsers()
      });
  }

  render() {

      ///<UserForm onUserCreate={this.loadUsers.bind(this)}/>
      // .bind(this) tells the context will be the parent one, not the child. so the loadUsers can solve this.loadUsers and refresh

    return this.state != null &&
          <div className="users-panel">
            <p>{this.state.message}</p>
            <table className="users-table">
              <tbody>
                <tr>
                  <th/>
                  <th>User</th>
                  <th>Email</th>
                  <th>Created</th>
                  <th/>
                </tr>
                {this.state.users.map((item, index) => (
                    <tr key={index}>
                      <td>{index+1}</td>
                      <td>{item.username}</td>
                      <td>{item.email}</td>
                      <td>{item.created}</td><td>
                      <img src={bin} alt="delete" onClick={(e) => this.deleteUser(item.username)} className="icon-button"/>
                      </td>
                    </tr>
                ))}
              </tbody>
            </table>
            <div>
              <UserForm onUserCreate={this.loadUsers.bind(this)} onLoading={this.loading.bind(this)}/>
            </div>
          </div>
  }

  private simplifyDate(created: string):string {
    return created !== undefined && created.length > 16 ? created.substring(2, 16).replace('T',' '):'-'
  }
}

