import React from 'react';
import {UserList} from "./UserList";
import {Status} from "./Status";
import {LoginForm} from "./LoginForm";

type GluonProps = {
}

type AuthenticationData = {
  username: string
  token: string
  loggedIn: boolean
}

type GluonState = {
  loading: boolean
  message: string
  function: string
  authData: AuthenticationData
}

let DEFAULT_FUNCTION = 'HOME'

let INITIAL_STATE = {
  loading: false,
  message: '',
  function: DEFAULT_FUNCTION,
  authData: {
    username: '',
    token: '',
    loggedIn: false
  }
}

export class GluonMain extends React.Component <GluonProps, GluonState> {
  constructor(props: GluonProps) {
    super(props);
    this.state = INITIAL_STATE
  }

  private handleStatus(loading: boolean, message: string) {
    this.setState(
        {
          loading: loading,
          message: message
        }
    )
  }

  private handleAuthData(authData: AuthenticationData) {
    this.setState(
        {
          authData: authData,
          function: DEFAULT_FUNCTION
        }
    )
  }

  isEnabled(funct: string) {
    switch(funct){
      case 'USERS':
      case 'LOGOUT':
        return this.state.authData.loggedIn
        // return this.state.authData.token && this.state.authData.token.length > 0;
      case 'LOGIN':
        return !this.state.authData.loggedIn
        // return (!this.state.authData.token ||
        //   (this.state.authData.token && this.state.authData.token.length == 0));
    }
    return false;
  }

  switchToHome() {
    this.setState({
      function: 'HOME'
    })
    console.log(this.state)
  }
  switchToUsers() {
    this.setState({
      function: 'USERS'
    })
    console.log(this.state)
  }
  switchToLogin() {
    this.setState({
      function: 'LOGIN'
    })
    console.log(this.state)
  }
  logout() {
    this.setState(INITIAL_STATE)
    console.log(this.state)
  }

  render() {
    return (
        <div>
          <button type="button" value="home" onClick={this.switchToHome.bind(this)}>Home</button>
          { this.isEnabled('USERS') &&
            <button type="button" value="users" onClick={this.switchToUsers.bind(this)}>Users</button>
          }
          { this.isEnabled('LOGIN') &&
            <button type="button" value="login" onClick={this.switchToLogin.bind(this)}>Login</button>
          }
          { this.isEnabled('LOGOUT') &&
          <button type="button" value="logout" onClick={this.logout.bind(this)}>Logout</button>
          }

          <div className="function-panel">
            <h1>{this.state.function.toLowerCase()}</h1>
          {
            this.state.function == 'USERS' &&
            <UserList
                title={"Users"}
                statusHandler={this.handleStatus.bind(this)}
            />
          }
          {
            this.state.function == 'HOME' && <div>----</div>
          }
          {
            this.state.function == 'LOGIN' &&
            <LoginForm
                statusHandler={this.handleStatus.bind(this)}
                authDataHandler={this.handleAuthData.bind(this)}
            />
          }
          </div>
          <Status loading={this.state.loading} message={this.state.message}/>
        </div>
    );
  }
}

