import React, {BaseSyntheticEvent, FormEvent} from 'react';
import './UserForm.css';

type UserFormProps = {
  onUserCreate: ()=>void
  onLoading: ()=>void
}

type UserFormState = {
  // numberOfGuests: number
  // isGoing: boolean
  username: string
  email: string
  password: string
  passwordCheck: string
}

let INITIAL_STATE = {
  username: '',
  email: '',
  password: '',
  passwordCheck: ''
}

export class UserForm extends React.Component <UserFormProps, UserFormState> {
// https://reactjs.org/docs/forms.html
//https://react-typescript-cheatsheet.netlify.app/docs/basic/getting-started/forms_and_events/

  constructor(props: UserFormProps) {
    super(props);
    this.state = INITIAL_STATE
    console.log(this.state)
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleInputChange(event: BaseSyntheticEvent) {
    const target = event.target;
    if (target == null) return
     const value = target.type === 'checkbox' ? target.checked : target.value;
     const name = target.name

    this.setState<never>({ //disable type check (PITA), otherwise field by field, or using Formik or React hook form
      [name]: value
    })
  }

  createUser() {
    console.log("should create " + this.state.username);
    this.props.onLoading()
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(this.state)
    };
    fetch('user/' + this.state.username, requestOptions)
    ///.then(response => response.json())
    .then(() => {
      this.props.onUserCreate()
      this.setState(INITIAL_STATE)
      //this.setState({ message: this.state.username + ' created' })
    });
    // //.then(data => this.setState({ postId: data.id }));
    //
    // fetch('user/' + user.username, { method: 'POST' })
    // .then(() => {
    //   this.setState({ message: user.username + ' created' })
    //   //this.loadUsers()
    // });
  }

  render() {
    return (
        <div>
          <form className="user-form">
            {/*{this.state.newUser != null &&*/}
            {/* // https://stackoverflow.com/questions/36067562/react-input-type-not-editable/41338877*/}
            {/*}*/}
            <table>
              <tr>
                <td><label>Username:</label></td>
                <td><input name="username" type="text" value={this.state.username} onChange={this.handleInputChange}/></td>
              </tr>
              <tr>
                <td><label>Email:</label></td>
                <td><input name="email" type="text" value={this.state.email} onChange={this.handleInputChange}/></td>
              </tr>
              <tr>
                <td><label>Password</label></td>
                <td><input name="password" type="password" autoComplete="new-password" value={this.state.password} onChange={this.handleInputChange}/></td>
              </tr>
              <tr>
                <td><label>Retype</label></td>
                <td><input name="passwordCheck" type="password" autoComplete="new-password" value={this.state.passwordCheck} onChange={this.handleInputChange}/></td>
              </tr>
              <tr>
                <td><label></label></td>
                <td><button type="button" value="create" onClick={(e) => this.createUser()}>Create</button></td>
              </tr>
            </table>


            {/*<label>*/}
            {/*  Is going:*/}
            {/*  <input*/}
            {/*      name="isGoing"*/}
            {/*      type="checkbox"*/}
            {/*      checked={this.state.isGoing}*/}
            {/*      onChange={this.handleInputChange} />*/}
            {/*</label>*/}
            {/*<br />*/}
            {/*<label>*/}
            {/*  Number of guests:*/}
            {/*  <input*/}
            {/*      name="numberOfGuests"*/}
            {/*      type="number"*/}
            {/*      value={this.state.numberOfGuests}*/}
            {/*      onChange={this.handleInputChange} />*/}
            {/*</label>*/}
          </form>
        </div>
    );
  }

}

