import React from 'react';

//https://stackoverflow.com/questions/41103360/how-to-use-fetch-in-typescript

// function search(query: String, cb: String){
//   api<string[]>('test/strings')
//   .then((list: string[]) => {
//     console.log(list)
//   })
//   .catch(error => {
//     /* show error message */
//   })
// }

// function searchUsers(query: String, cb: String){
//   api<{users:{ username: string; created: string ; email: string}[]}>('user')
//   .then(({ users }) => {
//     console.log(users)
//     for (let i = 0; i < users.length; i++) {
//       console.log(`${users[i].username} ${users[i].created} ${users[i].email} `)
//     }
//     return users
//   })
//   .catch(error => {
//     /* show error message */
//   })
// }

function api<T>(url: string): Promise<T> {
  return fetch(url)
  .then(response => {
    if (!response.ok) {
      throw new Error(response.statusText)
    }
    return response.json()
  })
}

//https://jasonwatmore.com/post/2020/11/11/react-fetch-http-delete-request-examples
// function deleteUser<T>(url: string): Promise<T> {
//   fetch('user/1', { method: 'DELETE' })
//   //.then(() => this.setState({ status: 'Delete successful' }));
//   return fetch(url)
//   .then(response => {
//     if (!response.ok) {
//       throw new Error(response.statusText)
//     }
//     return response.json()
//   })
//
// }

//https://kentcdodds.com/blog/using-fetch-with-type-script
// async function search(query: String, cb: String) {
//   const response = await window.fetch(`test/strings`, { //?q=${query}
//     headers: {
//       'accept' : 'application/json'
//     }
//   })
//   // .then(response =>{
//   //   console.log(response.body)
//   // });
//
//   const {data, errors} = await response.json()
//   console.log(response)
//   console.log(data)
//   console.log("qua")
//   if (response.ok) {
//     console.log(data)
//
//     const pokemon = data?.pokemon
//     if (pokemon) {
//       // add fetchedAt helper (used in the UI to help differentiate requests)
//       //pokemon.fetchedAt = formatDate(new Date())
//       return pokemon
//     } else {
//       return Promise.reject(new Error(`No pokemon with the name "${query}"`))
//     }
//   } else {
//     // handle the graphql errors
//     //const error = new Error(errors?.map(e => e.message).join('\n') ?? 'unknown')
//     //return Promise.reject(error)
//     return Promise.reject("asd")
//   }
//
//   // .then(checkStatus)
//   // .then(parseJSON)
//   // .then(cb);
// }

const Client = { api };
export default Client;
