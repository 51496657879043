import React from 'react';
import './App.css';
import {GluonMain} from "./GluonMain";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        Gluon
      </header>

      <div className="App-body">
        <GluonMain/>
      </div>
    </div>
  );
}

export default App;
