import React, {BaseSyntheticEvent, FormEvent} from 'react';
import './Status.css';
import logo from "./logo.svg";
import tick from "./img/tick.svg";

type StatusProps = {
  //onLoading: ()=>void
  loading: boolean
  message: string
}

type StatusState = {
  // numberOfGuests: number
  // isGoing: boolean
  loading: boolean

}

let INITIAL_STATE = {
  loading: true,
  message: ''
}

export class Status extends React.Component <StatusProps, StatusState> {
  constructor(props: StatusProps) {
    super(props);
    this.state = INITIAL_STATE
  }

  render() {
    return (
        <div className="status">
          <div className="status-icon">
            {this.props.loading &&
              <img src={logo} className="App-logo" alt="logo"/>
            }
            {!this.props.loading &&
            <img src={tick} className="tick" alt="logo"/>
            }
          </div>
          <div className="status-message">
            {this.props.message}
          </div>
        </div>
    );
  }

}

